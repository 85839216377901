import { combineReducers } from "redux"
import riwayatReducer from "./riwayat/riwayatReducer"
import klaimReducer from "./klaim/klaimReducer"
import detailReducer from "./detail/detailReducer"

const rootReducer = combineReducers({
  riwayatStore: riwayatReducer,
  klaimStore: klaimReducer,
  detailStore: detailReducer,
})

export default rootReducer
