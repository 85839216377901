export const setDataFormOne = (payload) => {
  return {
    type: "SET_DATA_FORM_ONE",
    payload,
  }
}

export const setClaimCargoId = (payload) => {
  return {
    type: "SET_CLAIM_CARGO_ID",
    payload,
  }
}

export const setDocsOtherIndexes = (payload) => {
  return {
    type: "SET_DOCS_OTHER_INDEXES",
    payload,
  }
}

export const resetKlaim = (payload) => {
  return {
    type: "RESET_KLAIM",
    payload,
  }
}
