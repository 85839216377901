export function InvoiceStatusFormat(value) {
  switch (value) {
    case 100:
      return "PAID";
    case 200:
      return "VERIFICATION";
    case 300:
      return "CANCEL";
    default:
      return "INITIAL";
  }
}
