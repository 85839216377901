import { MDBCol, MDBRow } from "mdb-react-ui-kit"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import axiosGet from "../../helpers/axiosGet"
import { setDataSearchRiwayat, resetRiwayat } from "../../store/riwayat/actions"

import DataTable from "../../components/DataTable/DataTable"
import Heading from "../../components/Heading/Heading"
import ScrollToHere from "../../components/ScrollToHere"
import SearchForm from "../../components/Form/SearchForm"

export default function Riwayat() {
  const dispatch = useDispatch()
  const dataSearchRiwayat = useSelector(
    (state) => state.riwayatStore.dataSearchRiwayat
  )

  useEffect(() => {
    setLoading(true)
    setError(false)

    axiosGet({
      url: `/claim-cargo/page/${dataSearchRiwayat.storedPage}?noClaim=${dataSearchRiwayat.storedValues.noClaim}&containerNo=${dataSearchRiwayat.storedValues.containerNo}&certificateNo=${dataSearchRiwayat.storedValues.certificateNo}&insured=${dataSearchRiwayat.storedValues.insured}&accidentType=${dataSearchRiwayat.storedValues.accidentType}&minAmount=${dataSearchRiwayat.storedValues.minAmount}&maxAmount=${dataSearchRiwayat.storedValues.maxAmount}&startDate=${dataSearchRiwayat.storedValues.startDate}&endDate=${dataSearchRiwayat.storedValues.endDate}&statusCode=${dataSearchRiwayat.storedValues.statusCode}`,
      callback: (res) => {
        setLoading(false)
        setClaims(res.data.data)
        setTotalPage(res.data.totalPage)
      },
      errorCallback: (err) => {
        setLoading(false)
        setError(err)
      },
    })
  }, [
    dataSearchRiwayat.storedPage,
    dataSearchRiwayat.storedValues.accidentType,
    dataSearchRiwayat.storedValues.certificateNo,
    dataSearchRiwayat.storedValues.containerNo,
    dataSearchRiwayat.storedValues.endDate,
    dataSearchRiwayat.storedValues.insured,
    dataSearchRiwayat.storedValues.maxAmount,
    dataSearchRiwayat.storedValues.minAmount,
    dataSearchRiwayat.storedValues.noClaim,
    dataSearchRiwayat.storedValues.policyNumber,
    dataSearchRiwayat.storedValues.startDate,
    dataSearchRiwayat.storedValues.statusCode,
  ])

  const [claims, setClaims] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [totalPage, setTotalPage] = useState(0)

  const handleSearch = (data) => {
    ScrollToHere()
    dispatch(
      setDataSearchRiwayat({
        storedValues: data,
        storedPage: 1,
      })
    )
  }

  const handleReset = () => {
    ScrollToHere()
    dispatch(resetRiwayat())
  }

  const handlePage = (value) => {
    ScrollToHere()

    let newPage = value.selected + 1

    dispatch(
      setDataSearchRiwayat({
        ...dataSearchRiwayat,
        storedPage: newPage,
      })
    )
  }

  const inputs = [
    {
      name: "noClaim",
      label: "No Klaim",
      type: "text",
    },
    {
      name: "containerNo",
      label: "Container No",
      type: "text",
    },
    {
      name: "certificateNo",
      label: "Certificate No",

      type: "text",
    },
    {
      name: "insured",
      label: "Insured",
      type: "text",
    },
    {
      name: "accidentType",
      label: "Jenis Kejadian",
      type: "dropdown",
      options: [
        {
          name: "ALL",
          value: "",
        },
        {
          name: "Damage Cargo",
          value: "DAMAGE",
        },
        {
          name: "Shortage Cargo",
          value: "SHORTAGE",
        },
      ],
    },
    {
      name: "minAmount",
      label: "Min Kerugian",
      type: "currency",
    },
    {
      name: "maxAmount",
      label: "Max Kerugian",
      type: "currency",
    },
    {
      name: "startDate",
      label: "Klaim Date From",
      type: "date",
    },
    {
      name: "endDate",
      label: "Klaim Date To",
      type: "date",
    },
    {
      name: "statusCode",
      label: "Status",
      type: "dropdown",
      options: [
        {
          name: "ALL",
          value: "",
        },
        {
          name: "Initial Report",
          value: 100,
        },
        {
          name: "Waiting for Document Completion",
          value: 110,
        },
        {
          name: "Checking Document",
          value: 120,
        },

        {
          name: "Submit LOD",
          value: 130,
        },

        {
          name: "Waiting LOD Sign",
          value: 140,
        },
        {
          name: "Submit LOD Sign",
          value: 150,
        },

        {
          name: "Submit Claim to Insurance",
          value: 200,
        },
        {
          name: "Submit Doc Claim to Insurance",
          value: 210,
        },
        {
          name: "Claim Approved",
          value: 300,
        },
        {
          name: "Claim Paid",
          value: 310,
        },
        {
          name: "Claim Rejected",
          value: 400,
        },
      ],
    },
  ]

  const headers = [
    {
      name: (
        <>
          <div>No Klaim</div>
          <div>No Sertifikat</div>
          <div>No Polis</div>
        </>
      ),
    },
    {
      name: (
        <>
          <div>Insured</div>
          <div>No Kontainer</div>
          <div>Tanggal Kejadian</div>
        </>
      ),
    },
    {
      name: (
        <>
          <div>Estimasi Kerugian</div>
          <div>Tanggal Klaim</div>
          <div>Jenis Kejadian</div>
        </>
      ),
    },
    {
      name: (
        <>
          <div>TSI</div>
          <div>Premi</div>
          <div>Status</div>
        </>
      ),
    },
    {
      name: "Detail",
    },
  ]

  return (
    <>
      <Heading>RIWAYAT KLAIM</Heading>

      <section className="overflow-hidden">
        <MDBRow className="g-4">
          <MDBCol md="12">
            <SearchForm
              inputs={inputs}
              handleSearch={handleSearch}
              handleReset={handleReset}
              defVals={dataSearchRiwayat.storedValues}
            />
          </MDBCol>

          <MDBCol md="12">
            <DataTable
              rows={claims}
              headers={headers}
              loading={loading}
              error={error}
              handleScroll={ScrollToHere}
              page={dataSearchRiwayat.storedPage}
              totalPage={totalPage}
              handlePage={handlePage}
            />
          </MDBCol>
        </MDBRow>
      </section>
    </>
  )
}
