import { MDBCard, MDBCardBody } from "mdb-react-ui-kit";
import styled from "styled-components";

const StyledCard = styled.div`
  font-family: "Avenir", sans-serif;

  hr {
    margin: 0.5rem 0;
  }
`;

const CardWrapper = ({ children }) => {
  return (
    <StyledCard>
      <MDBCard>
        <MDBCardBody>
          <div className="py-lg-2 px-lg-3">{children}</div>
        </MDBCardBody>
      </MDBCard>
    </StyledCard>
  );
};

export default CardWrapper;
