import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "../../components/Spinner/Spinner";
import Container from "../../components/Container/Container";
import { setDetailFromCargo } from "../../store/detail/actions";
import { useDispatch } from "react-redux";
import axiosGet from "../../helpers/axiosGet";
import axios from "axios";
import constants from "../../constants/constants";

const Home = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  // get queries
  const token = searchParams.get("token");
  const userCode = searchParams.get("user");
  const data = searchParams.get("data");
  const type = searchParams.get("type");
  const refId = searchParams.get("refId");
  const signature = searchParams.get("signature");

  const setBaseURL = () => {
    const signature = sessionStorage.getItem("signature");
    axios.defaults.baseURL = signature
      ? constants.GATEWAY_URL
      : constants.DEFAULT_URL;
  };

  useEffect(() => {
    if (token && userCode) {
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("userCode", userCode);
      sessionStorage.setItem("type", type);
      sessionStorage.setItem("signature", signature);

      if (data) {
        // replace whitespace with '+'
        const replace = data.replace(/\s/g, "+");

        // decode base64
        const decode = window.atob(replace);

        // parse to json
        const parse = JSON.parse(decode);
        dispatch(setDetailFromCargo(parse));

        setTimeout(() => {
          navigate("/klaim");
        }, 100);
      } else if (type && type === "list") {
        setTimeout(() => {
          navigate("/riwayat");
        }, 100);
      } else if (refId) {
        setBaseURL();
        axios.defaults.headers.common["X-Api-Key"] = constants.API_KEY_GENERAL;
        axiosGet({
          url: `/polismall-cargo-all/declaration/ref-id/${refId}`,
          callback: (res) => {
            const data = res.data;
            dispatch(setDetailFromCargo(data));
            setTimeout(() => {
              navigate("/klaim");
            }, 1000);
          },
          errorCallback: (err) => {
            console.log(err);
          },
        });
      } else {
        setTimeout(() => {
          // navigate("/404")
          navigate("/klaim/check");
        }, 100);
      }
    } else {
      setTimeout(() => {
        navigate("/404");
      }, 100);
    }
  }, [data, dispatch, navigate, token, type, userCode, refId, signature]);

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

export default Home;
