import { MDBSpinner } from "mdb-react-ui-kit"

export default function Spinner({ height }) {
  return (
    <div
      className="d-flex align-items-center justify-content-center gap-4"
      style={{
        height: height && `${height}vh`,
      }}
    >
      <MDBSpinner grow size="sm">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
      <MDBSpinner grow>
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
      <MDBSpinner grow size="sm">
        <span className="visually-hidden">Loading...</span>
      </MDBSpinner>
    </div>
  )
}
