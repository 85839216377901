import { useState } from "react"
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBCollapse,
} from "mdb-react-ui-kit"
import Logo from "../../assets/img/polismall.png"
import { Link, useLocation } from "react-router-dom"
import "./Navbar.css"

export default function Navbar() {
  const [showBasic, setShowBasic] = useState(false)

  const location = useLocation()

  return (
    <MDBNavbar
      expand="md"
      light
      bgColor="light"
      fixed="top"
      className="shadow-0"
    >
      <MDBContainer fluid>
        <Link to="/riwayat">
          <img src={Logo} alt="polismall logo" />
        </Link>

        <MDBNavbarToggler
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon="bars" fas />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic}>
          <MDBNavbarNav right fullWidth={false}>
            {/* <MDBNavbarItem className="py-1 py-md-0 me-md-3">
              <Link
                to="/klaim"
                className={
                  location.pathname === "/klaim" ? "text-body" : "text-dark"
                }
              >
                Klaim
              </Link>
            </MDBNavbarItem> */}
            <MDBNavbarItem className="py-1 py-md-0">
              <Link
                to="/riwayat"
                className={
                  location.pathname === "/riwayat" ? "text-body" : "text-dark"
                }
              >
                Riwayat
              </Link>
            </MDBNavbarItem>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
  )
}
