import axios from "axios"
import generateCorrelationId from "./generateCorrelationId"
import getCurrentDatetime from "./getCurrentDatetime"

export default function axiosGet({
  url,
  headers,
  callback,
  errorCallback,
  responseType,
}) {
  axios
    .get(url, {
      headers: {
        ...headers,
        "X-Transmission-Date-Time": getCurrentDatetime(),
        "X-Correlation-Id": generateCorrelationId(),
      },
      responseType: responseType,
    })
    .then((res) => {
      if (res.data.statusCode === 200) {
        callback(res.data)
      } else {
        errorCallback(res.data)
      }
    })
    .catch((err) => {
      errorCallback(err)
    })
}
