import Container from "../../components/Container/Container"

const NotFound = () => {
  return (
    <Container>
      <h1>404</h1>
      <p>Page not found</p>
    </Container>
  )
}

export default NotFound
