const FileType = [
  {
    type: "DOC_CLAIM",
    text: "Dokumen Klaim",
  },
  {
    type: "DOC_AIRWAY",
    text: "Bills of Lading / Airway",
  },
  {
    type: "DOC_DELIVERY_RECEIPT",
    text: "Surat Jalan / Delivery Receipt",
  },
  {
    type: "DOC_PACKING_LIST",
    text: "Packing List",
  },
  {
    type: "DOC_INVOICE",
    text: "Invoice Nilai Rinci Seluruh Muatan",
  },
  {
    type: "DOC_CLAIM_REPORT",
    text: "Berita Acara Kerugian",
  },
  {
    type: "DOC_DOCUMENTATION",
    text: "Foto - Foto Dokumentasi",
  },
  {
    type: "DOC_EIR",
    text: "EIR Sebelum Stuffing",
  },
  {
    type: "DOC_TALLY_SHEET",
    text: "Tally Sheet",
  },
  {
    type: "DOC_POLICE_REPORT",
    text: "Surat Laporan Kepolisian",
  },
  {
    type: "DOC_OTHER",
    text: "Lainnya",
  },
  {
    type: "DOC_LOD",
    text: "Dokumen LOD",
  },
  {
    type: "DOC_LOD_SIGNED",
    text: "Dokumen LOD Sign",
  },
  {
    type: "DOC_PAYMENT",
    text: "Bukti Pembayaran",
  },
  {
    type: "DOC_SPGR",
    text: "Surat Perjanjian Ganti Rugi",
  },
  {
    type: "DOC_STP3",
    text: "Surat Tuntutan Pihak Ke-3",
  },
]

export default FileType
