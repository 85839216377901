import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBInput,
  MDBBtn,
} from "mdb-react-ui-kit";
import moment from "moment";

import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import NumberFormat from "react-number-format";

import axiosGet from "../../helpers/axiosGet";
import axiosPut from "../../helpers/axiosPut";

import Button from "../../components/Button/Button";
import CertificateDetail from "../../components/Detail/CertificateDetail";
import ClaimDetail from "../../components/Detail/ClaimDetail";
import Container from "../../components/Container/Container";
import Heading from "../../components/Heading/Heading";
import Spinner from "../../components/Spinner/Spinner";
import StatusBar from "../../components/StatusBar/StatusBar";
import Subheading from "../../components/Subheading/Subheading";

const RiwayatDetail = () => {
  const { id } = useParams();
  const decode = window.atob(id);

  const [loading, setLoading] = useState(true);
  const [detail, setDetail] = useState({});
  const [claim, setClaim] = useState({});
  const [docs, setDocs] = useState([]);
  const [docLod, setDocLod] = useState({});
  const [currentStatus, setCurrentStatus] = useState({});
  const [statusHistory, setStatusHistory] = useState([]);
  const [isComplete, setIsComplete] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const [dataInvoice, setDataInvoice] = useState(null);

  const { handleSubmit, control } = useForm();

  const [conversationsLoaded, setConversationsLoaded] = useState(false);

  useEffect(() => {
    const getAllConversations = async () => {
      await axiosGet({
        url: `/claim-cargo/id/${decode}`,
        callback: (res) => {
          setLoading(false);
          setDetail(res.data);
          setClaim(res.data.claim);
          setConversationsLoaded(true);

          const claimCargoStatus = res.data.claimCargoStatus;
          setStatusHistory(claimCargoStatus);
          setCurrentStatus({
            ...claimCargoStatus[0],
            paymentDate: res.data.claim.paymentDate,
          });

          const claimCargoDocument = res.data.claimCargoDocument;
          const accidentType = res.data.accidentType;
          const statusCode = claimCargoStatus[0].statusCode;

          // cek dokumen, jika sudah lengkap show tombol submit
          const isComplete = checkDocs(claimCargoDocument, accidentType);

          setIsComplete(
            ["110", "111", "201"].includes(statusCode) && isComplete
              ? true
              : false
          );
          setDocs(claimCargoDocument);

          const docLod = claimCargoDocument.filter(
            (doc) => doc.type === "DOC_LOD"
          );
          setDocLod(...docLod);

          const expiredDate = res.data.claim.expiredDate;
          const today = moment();
          const isExpired = moment(today).isAfter(expiredDate);
          setIsExpired(isExpired);
        },
        errorCallback: (err) => {
          setLoading(false);
        },
      });
    };
    getAllConversations();
  }, [decode]);

  useEffect(() => {
    if (conversationsLoaded === true) {
      axiosGet({
        url: `/claim-cargo/invoice/${detail?.claim?.refId}`,
        callback: (res) => {
          setDataInvoice(res.data);
        },
        errorCallback: (res) => {
          console.log(res);
        },
      });
    }
  }, [detail?.claim?.refId, conversationsLoaded]);

  const checkDocs = (docs, type) => {
    const docTypes = [
      "DOC_CLAIM",
      "DOC_AIRWAY",
      "DOC_DELIVERY_RECEIPT",
      "DOC_PACKING_LIST",
      "DOC_INVOICE",
      "DOC_CLAIM_REPORT",
      "DOC_DOCUMENTATION",
      "DOC_EIR",
      "DOC_TALLY_SHEET",
      "DOC_POLICE_REPORT",
      "DOC_STP3",
    ];

    if (type === "SHORTAGE") {
      return docTypes.every((x) => docs.some((y) => y.type === x));
    } else {
      return docTypes.splice(0, 7).every((x) => docs.some((y) => y.type === x));
    }
  };

  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    setLoading(true);

    const fd = new FormData();

    fd.append("noClaim", detail.noClaim);
    if (data.description) {
      fd.append("description", data.description || "");
    }
    if (data.salvageAmount) {
      fd.append("salvageAmount", data.salvageAmount);
    }

    axiosPut({
      url: `/claim-cargo/checking-document`,
      data: fd,
      callback: () => {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      },
      errorCallback: (err) => {
        console.log(err);
      },
    });
  };

  const [confirmModal, setConfirmModal] = useState(false);

  return loading ? (
    <Container>
      <Spinner />
    </Container>
  ) : (
    <>
      <section className="overflow-hidden">
        <StatusBar
          id={id}
          detail={detail}
          status={currentStatus}
          claim={claim}
          doc={docLod}
          isExpired={isExpired}
        />

        <MDBContainer>
          <Heading status={currentStatus.statusCode}>DETAIL KLAIM</Heading>

          <MDBRow className="g-4">
            <MDBCol lg="8">
              <ClaimDetail detail={detail} docs={docs} claim={claim} />
            </MDBCol>

            <MDBCol lg="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail
                detail={detail}
                claim={claim}
                currentStatus={currentStatus}
                docs={docs}
                statusHistory={statusHistory}
                invoice={dataInvoice}
              />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-between mt-5">
            <Link to="/riwayat">
              <Button secondary startIcon="arrow-left">
                Kembali
              </Button>
            </Link>

            {isComplete && !isExpired && (
              <Button onClick={() => setConfirmModal(true)}>
                Submit Claim
              </Button>
            )}
          </div>
        </MDBContainer>
      </section>

      <MDBModal tabIndex="-1" show={confirmModal} setShow={setConfirmModal}>
        <MDBModalDialog centered>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Submit Salvage Amount</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  name="salvageAmount"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <NumberFormat
                      name={name}
                      thousandSeparator="."
                      decimalSeparator=","
                      value={value}
                      onValueChange={(value) => onChange(value.floatValue)}
                      className="form-control form-control-lg"
                      getInputRef={ref}
                      onBlur={onBlur}
                      placeholder="Salvage Amount"
                    />
                  )}
                />

                <div
                  style={{
                    color: "red",
                    marginBottom: "-15px",
                    marginTop: "15px",
                  }}
                >
                  <b>
                    <i>
                      *Notes: mohon dilewati apabila tidak ada nilai salvage
                    </i>
                  </b>
                </div>
                <Controller
                  name="description"
                  control={control}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                  }) => (
                    <MDBInput
                      name={name}
                      value={value}
                      onChange={onChange}
                      inputRef={ref}
                      onBlur={onBlur}
                      label="Description"
                      className="mt-3 form-control form-control-lg"
                    />
                  )}
                />

                <MDBBtn
                  type="submit"
                  style={{
                    marginTop: "20px",
                    width: "100%",
                    backgroundColor: "#006881",
                  }}
                >
                  SUBMIT
                </MDBBtn>
              </form>
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
};

export default RiwayatDetail;
