const initialState = {
  dataSearchRiwayat: {
    storedValues: {
      noClaim: "",
      containerNo: "",
      certificateNo: "",
      insured: "",
      accidentType: "",
      minAmount: "",
      maxAmount: "",
      startDate: "",
      endDate: "",
      statusCode: "",
    },
    storedPage: 1,
  },
}

const riwayatReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA_SEARCH_RIWAYAT":
      return {
        ...state,
        dataSearchRiwayat: action.payload,
      }
    case "RESET_RIWAYAT":
      return (state = initialState)

    default:
      return state
  }
}

export default riwayatReducer
