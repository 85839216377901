import { MDBBadge, MDBCol, MDBRow, MDBTooltip } from "mdb-react-ui-kit"
import { useForm, Controller } from "react-hook-form"
import NumberFormat from "react-number-format"
import styled from "styled-components"
import moment from "moment"
import CardWrapper from "../Card/CardWrapper"

const inputs = [
  {
    name: "containerNo",
    label: "Nomor Kontainer",
    type: "textarea",
    required: true,
  },
  {
    name: "claimAmount",
    label: "Estimasi Kerugian",
    type: "currency",
    required: true,
  },
  {
    name: "accidentDate",
    label: "Tanggal Kejadian",
    type: "date",
    required: true,
  },
  {
    name: "picName",
    label: "PIC Name",
    type: "text",
    required: true,
  },
  {
    name: "picEmail",
    label: "PIC Email",
    type: "email",
    required: true,
  },
  {
    name: "picPhoneNumber",
    label: "PIC No Handpone",
    type: "number",
    required: true,
    minLength: 8,
    maxLength: 13,
  },
  {
    name: "typeOfLoss",
    label: "Jenis Kerugian",
    type: "dropdown",
    required: true,
    options: [
      {
        name: "Damage upon arrival/rough handling",
        value: "Damage upon arrival/rough handling"
      },
      {
        name: "Wet damage",
        value: "Wet damage"
      },
      {
        name: "Shortage",
        value: "Shortage"
      },
      {
        name: "Theft/burglary",
        value: "Theft/burglary"
      },
      {
        name: "Others (by accident)",
        value: "Others (by accident)"
      }
    ]
  },
  {
    name: "accidentLocation",
    label: "Lokasi Kejadian (Alamat Lengkap)",
    type: "textarea",
    required: true,
  },
  {
    name: "accidentDetail",
    label: "Detail Kronologi Kejadian",
    type: "textarea",
    required: true,
  },
]

const conditionalInputs = [
  {
    name: "accidentType",
    label: "Jenis Kejadian",
    type: "radio",
    required: true,
    options: [
      {
        name: "damageCargo",
        label: "Damage Cargo",
        value: "DAMAGE",
        tooltip:
          "Damage Cargo adalah kargo yang ditemukan dalam keadaan rusak baik itu untuk kerusakan packing, isi, mutu dari kargo itu sendiri.",
      },
      {
        name: "shortageCargo",
        label: "Shortage Cargo",
        value: "SHORTAGE",
        tooltip:
          "Shortage Cargo adalah kargo yang ditemukan dalam keadaan barang yang kurang pada saat barang tersebut sampai tujuan. Dalam hal ini, penyelesaiannya dilakukan berdasarkan Harga Pertanggungan dari barang yang kurang diserahkan tersebut.",
      },
    ],
  },
]

const StyledHeader = styled.div`
  font-weight: 700;
  padding: 1rem;
  background: #f5f5f7;
  border-radius: 0.25rem;
`

const Form = ({ header, id, handleNext, defVals, detailFromCargo }) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    watch,
    setError,
  } = useForm({ defaultValues: defVals, shouldUnregister: true })

  const onSubmit = (data) => {
    // validasi tanggal kejadian harus dalam 21 hari ke belakang
    const date = data.accidentDate
    const today = moment().format("YYYY-MM-DD")
    const diff = moment(date).diff(today, "days")

    // validasi estimasi kerugian ga boleh lebih dari TSI
    const loss = data.claimAmount
    const TSI = parseInt(detailFromCargo.sumInsured)

    if (diff > 0 || diff < -20) {
      setError(
        "accidentDate",
        {
          type: "manual",
          message: "Maksimal tanggal kejadian 21 hari ke belakang",
        },
        {
          shouldFocus: true,
        }
      )
    } else if (loss > TSI) {
      setError(
        "claimAmount",
        {
          type: "manual",
          message: "Estimasi Kerugian tidak boleh lebih besar dari TSI",
        },
        {
          shouldFocus: true,
        }
      )
    } else {
      handleNext(data)
    }
  }

  // watch conditional input
  const claimAmountValue = watch("claimAmount")

  return (
    <CardWrapper>
      <form onSubmit={handleSubmit(onSubmit)} id={id} noValidate>
        <MDBRow className="g-4">
          {header && (
            <MDBCol size="12">
              <p className="mb-0">Insured</p>

              <StyledHeader>{header}</StyledHeader>
            </MDBCol>
          )}

          {inputs.map((input) => (
            <MDBCol size="12" key={input.name}>
              <Input
                input={input}
                rhf={{
                  register: register,
                  control: control,
                  errors: errors,
                }}
              />
            </MDBCol>
          ))}

          {/* render conditional input */}
          {claimAmountValue > 50_000_000 && (
            <MDBCol size="12">
              <Input
                input={conditionalInputs[0]}
                rhf={{
                  register: register,
                  control: control,
                  errors: errors,
                }}
              />
            </MDBCol>
          )}
        </MDBRow>
      </form>
    </CardWrapper>
  )
}

const Input = ({ input, rhf }) => {
  const { name, label, required } = input
  const { errors } = rhf

  return (
    <>
      <label htmlFor={name}>
        {label}
        {required && <span className="text-danger">*</span>}
      </label>

      <RenderedInput input={input} rhf={rhf} />
      {name === 'containerNo' && (
        <div style={{ fontSize: '14px' }}><i>Jika claim lebih dari satu container dipisahkan dengan koma (,), contoh: KCA01, KCA02, KCA03</i></div>
      )}

      {errors[name] && (
        <p className="mb-0 mt-1 text-danger small">*{errors[name].message}</p>
      )}
    </>
  )
}

const RenderedInput = ({ input, rhf }) => {
  const { name, label, type, required, options, minLength, maxLength } = input
  const { register, control } = rhf

  switch (type) {
    case "number":
      return (
        <Controller
          name={name}
          control={control}
          rules={{
            required: required && `${label} is required`,
            minLength: {
              value: minLength,
              message: `Minimal ${minLength} digit`,
            },
            maxLength: {
              value: maxLength,
              message: `Maksimal ${maxLength} digit`,
            },
          }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <NumberFormat
              name={name}
              id={name}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              className="form-control form-control-lg"
              getInputRef={ref}
              placeholder={label}
            />
          )}
        />
      )
    case "email":
      return (
        <input
          type="email"
          className="form-control form-control-lg"
          id={name}
          {...register(name, {
            required: required && `${label} is required`,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email",
            },
          })}
          placeholder={label}
        />
      )
    case "currency":
      return (
        <Controller
          name={name}
          control={control}
          rules={{ required: required && `${label} is required` }}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <NumberFormat
              name={name}
              id={name}
              thousandSeparator="."
              decimalSeparator=","
              value={value}
              onValueChange={(value) => onChange(value.floatValue)}
              onBlur={onBlur}
              className="form-control form-control-lg"
              getInputRef={ref}
              placeholder={label}
            />
          )}
        />
      )
    case "date":
      return (
        <input
          type="date"
          className="form-control form-control-lg"
          id={name}
          {...register(name, {
            required: required && `${label} is required`,
          })}
          placeholder={label}
          min={moment().subtract(20, "days").format("YYYY-MM-DD")}
          max={moment().format("YYYY-MM-DD")}
        />
      )
    case "textarea":
      return (
        <textarea
          rows="5"
          className="form-control form-control-lg"
          id={name}
          {...register(name, { required: required && `${label} is required` })}
          placeholder={label}
        />
      )
    case "dropdown":
      return (
        <div className="select-wrapper">
          <select
            className="form-control form-control-lg"
            {...register(name, {
              required: required && `${label} is required`,
            })}
          >
            <option value="">{label}</option>
            {options.map((option) => (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      )
    case "radio":
      return (
        <div>
          {options.map((option) => (
            <div
              key={option.name}
              {...register(name, {
                required: required && `${label} is required`,
              })}
              className="d-flex align-items-center"
            >
              <input
                type="radio"
                name={name}
                id={option.name}
                value={option.value}
              />

              <label htmlFor={option.name} className="ms-2">
                {option.label}
              </label>

              <MDBTooltip tag={"a"} placement="right" title={option.tooltip}>
                <MDBBadge color="dark" className="ms-2">
                  ?
                </MDBBadge>
              </MDBTooltip>
            </div>
          ))}
        </div>
      )

    default:
      return (
        <input
          type="text"
          className="form-control form-control-lg"
          id={name}
          {...register(name, {
            required: required && `${label} is required`,
            minLength: {
              value: minLength,
              message: `Minimal ${minLength} digit`,
            },
            maxLength: {
              value: maxLength,
              message: `Maksimal ${maxLength} digit`,
            },
          })}
          placeholder={label}
        />
      )
  }
}

export default Form
