import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { MDBCol, MDBIcon, MDBRow, MDBSpinner } from "mdb-react-ui-kit";
import { saveAs } from "file-saver";
import styled from "styled-components";
import * as uniqId from "uniqid";

import axiosGet from "../../helpers/axiosGet";
import axiosPost from "../../helpers/axiosPost";
import axiosDelete from "../../helpers/axiosDelete";

import Button from "../Button/Button";
import CardWrapper from "../Card/CardWrapper";

const fields = [
  {
    name: "DOC_CLAIM",
    label: "Upload Formulir Klaim",
  },
  {
    name: "DOC_STP3",
    label: "Upload Surat Tuntutan Pihak Ke-3",
  },
  {
    name: "DOC_AIRWAY",
    label: "Bills of Lading / Airway",
  },
  {
    name: "DOC_DELIVERY_RECEIPT",
    label: "Surat Jalan / Delivery Receipt",
  },
  {
    name: "DOC_PACKING_LIST",
    label: "Packing List",
  },
  {
    name: "DOC_INVOICE",
    label: "Invoice Nilai Rincian Seluruh Muatan",
  },
  {
    name: "DOC_CLAIM_REPORT",
    label: "Berita Acara Kerugian",
  },
  {
    name: "DOC_DOCUMENTATION",
    label:
      "Foto - foto dokumentasi berwarna dan atau rekaman video (kontainer, seal, barang yang rusak)",
  },
];

const conditionalFields = [
  {
    name: "DOC_EIR",
    label: "EIR Sebelum Stuffing",
  },
  {
    name: "DOC_TALLY_SHEET",
    label: "Tally Sheet",
  },
  {
    name: "DOC_POLICE_REPORT",
    label: "Surat Laporan Kepolisian",
  },
];

const StyledInput = styled.div`
  /* background: #ffffff; */
  background: ${({ isExist }) => (isExist ? "#f5f5f5" : "#ffffff")};
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledWrapper = styled.div`
  position: relative;
`;

const StyledAbsoluteInput = styled.div`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

const StyledButton = styled.button`
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  color: white;
  min-height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${(props) =>
    props.isDelete ? "#F93154" : props.isDownload ? "#ADDFFF" : "#43BA94"};
  border: none;
  pointer-events: ${(props) => props.isUpload && "none"};
  min-width: ${(props) => (props.isDelete ? "40px" : "90px")};

  :disabled {
    background: #757575;
  }

  i {
    pointer-events: none;
  }
`;

const FileForm = ({
  formId,
  handleNext,
  data,
  docs,
  docsOther,
  disableNext,
}) => {
  const { claimAmount, accidentType } = data;

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({ shouldUnregister: true });

  const onSubmit = (data) => {
    handleNext(data);
  };

  return (
    <CardWrapper>
      <form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <MDBRow className="g-4">
          {fields.map((field) => (
            <MDBCol size="12" key={field.name}>
              <Field
                field={field}
                rhf={{
                  register: register,
                  errors: errors,
                  watch: watch,
                  setError: setError,
                  clearErrors: clearErrors,
                }}
                data={data}
                docs={docs}
                disableNext={disableNext}
              />
            </MDBCol>
          ))}

          {claimAmount > 50_000_000 && accidentType === "DAMAGE" && (
            <MDBCol size="12">
              <Field
                field={conditionalFields[0]}
                rhf={{
                  register: register,
                  errors: errors,
                  watch: watch,
                  setError: setError,
                  clearErrors: clearErrors,
                }}
                data={data}
                docs={docs}
                disableNext={disableNext}
              />
            </MDBCol>
          )}

          {claimAmount > 50_000_000 &&
            accidentType === "SHORTAGE" &&
            conditionalFields.map((field) => (
              <MDBCol size="12" key={field.name}>
                <Field
                  field={field}
                  rhf={{
                    register: register,
                    errors: errors,
                    watch: watch,
                    setError: setError,
                    clearErrors: clearErrors,
                  }}
                  data={data}
                  docs={docs}
                  disableNext={disableNext}
                />
              </MDBCol>
            ))}

          <FieldArray
            rhf={{
              register: register,
              errors: errors,
              setError: setError,
              clearErrors: clearErrors,
            }}
            data={data}
            disableNext={disableNext}
            docs={docsOther}
          />
        </MDBRow>
      </form>
    </CardWrapper>
  );
};

const Field = ({ field, rhf, data, docs, disableNext }) => {
  const { name, label } = field;
  const { register, errors, watch, setError, clearErrors } = rhf;
  const { id, claim } = data;

  const statusCode = claim?.statusCode;

  const value = watch(name);

  let doc = {};
  docs.forEach((element) => {
    if (element.type === name) {
      doc.isExist = true;
      doc.fileId = element.fileId;
      doc.claimCargoId = element.claimCargoId;
      doc.filename = element.filename;
      doc.description = element.description
      doc.revisionNotes = element.revisionNotes
    }
  });

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);

  const handleLampirkan = (e) => {
    const file = e.target.files[0];
    clearErrors(name);

    if (file) {
      //  max size DOC_DOCUMENTATION adalah 50MB
      if (name === "DOC_DOCUMENTATION" && file.size > 50_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 50MB",
          },
          {
            shouldFocus: true,
          }
        );
      }
      //  selain itu, max size adalah 5MB
      else if (file.size > 5_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 5MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);

        const fd = new FormData();

        fd.append("claimCargoId", id);
        fd.append("file", file);
        fd.append("type", name);

        axiosPost({
          url: "/claim-cargo/upload",
          data: fd,
          callback: (res) => {
            // cek tipe dokumen, apakah ada yang sama atau tidak
            const index = docs.findIndex((doc) => doc.type === name);

            // jika tidak, push dokumen baru
            if (index === -1) {
              docs.push(res.data);
            } else {
              // jika ada yang sama, replace dokumen yang lama
              docs[index] = res.data;
            }

            setUploadLoading(false);
            disableNext(false);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            console.log(err);
          },
        });
      }
    }
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-cargo/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  return (
    <>
      <label htmlFor={name}>{label}</label>

      <input
        type="file"
        className="form-control form-control-lg"
        id={name}
        name={name}
        {...register(name, {
          onChange: handleLampirkan,
        })}
        style={{ display: "none" }}
        accept={
          name === "DOC_STP3"
            ? ".doc, .docx, .pdf"
            : ".jpg, .jpeg, .png, .pdf, .mp4, .3gp, .webm, .mkv, .avi, .mpeg, .m4v"
        }
      />

      <StyledInput isExist={doc.isExist}>
        <span>
          {value?.length
            ? value[0].name
            : doc.isExist
              ? doc.filename
              : "Pilih file"}
        </span>

        <div>
          {doc.isExist && (
            <StyledButton
              type="button"
              disabled={downloadLoading}
              onClick={() => handleDownload(doc.fileId, doc.filename)}
              isDownload
            >
              {downloadLoading ? (
                <MDBSpinner role="status" size="sm">
                  <span className="visually-hidden">Loading...</span>
                </MDBSpinner>
              ) : (
                "Download"
              )}
            </StyledButton>
          )}

          {
            ((["111", "201"].includes(statusCode) && doc.revisionNotes) || ["100", "110"].includes(statusCode)) && (
              <label htmlFor={name}>
                <div style={{ cursor: "pointer" }}>
                  <StyledButton type="button" disabled={uploadLoading} isUpload>
                    {uploadLoading ? (
                      <MDBSpinner role="status" size="sm">
                        <span className="visually-hidden">Loading...</span>
                      </MDBSpinner>
                    ) : (
                      "Lampirkan"
                    )}
                  </StyledButton>
                </div>
              </label>
            )
          }
        </div>
      </StyledInput>

      {
        doc.revisionNotes && (
          <div
            style={{
              fontSize: '12px',
              marginTop: '10px',
              fontWeight: 'bold',
              color: 'red',
              fontStyle: 'italic'
            }}
          >
            Notes: {doc.revisionNotes}
          </div>
        )
      }

      {errors[name] && (
        <p className="mb-0 mt-1 text-danger small">*{errors[name].message}</p>
      )}
    </>
  );
};

const FieldArray = ({ rhf, data, docs, disableNext }) => {
  const [fields, setFields] = useState(docs);
  const [addFieldDisabled, setAddFieldDisabled] = useState(false);

  useEffect(() => {
    if (docs) {
      setFields(docs);
    }
  }, [docs]);

  const addField = () => {
    const newField = {
      id: uniqId(),
      description: "",
    };

    setFields([...fields, newField]);
  };

  const removeField = (id) => {
    const filteredDocs = fields.filter((field) => field.id !== id);
    setFields(filteredDocs);
  };

  const changeField = (data, id) => {
    const newFields = [...fields];

    const index = newFields.findIndex((field) => field.id === id);

    if (index === -1) {
      // do nothing
    } else {
      // change object value with new data
      newFields[index] = data;
      setFields(newFields);
    }
  };

  const addDescription = (desc, id) => {
    const newFields = [...fields];

    const newFieldsWithDesc = newFields.map((field) => {
      return field.id === id
        ? {
          ...field,
          description: desc,
        }
        : field;
    });

    setFields(newFieldsWithDesc);
  };

  const disableAddField = (value) => {
    setAddFieldDisabled(value);
  };

  return (
    <>
      {fields?.map((field, index) => (
        <MDBCol size="12" key={field.id}>
          <SingleFieldArray
            index={index}
            field={field}
            rhf={rhf}
            data={data}
            docs={docs}
            disableNext={disableNext}
            handleField={{
              changeField: changeField,
              removeField: removeField,
              addDescription: addDescription,
              disableAddField: disableAddField,
            }}
          />
        </MDBCol>
      ))}

      <MDBCol size="12">
        <Button
          small
          onClick={addField}
          disabled={addFieldDisabled}
          startIcon="plus"
        >
          DOKUMEN LAINNYA
        </Button>
      </MDBCol>
    </>
  );
};

const SingleFieldArray = ({
  index,
  field,
  rhf,
  data,
  disableNext,
  handleField,
}) => {
  const { register, errors, setError, clearErrors } = rhf;
  const { id } = data;
  const { changeField, removeField, addDescription, disableAddField } =
    handleField;

  const name = `DOC_OTHER_${index}`;

  const desc = `${name}_DESC`;
  const descLabel = "Nama Deskripsi";

  const isExist = field.filename ? true : false;

  const [downloadLoading, setDownloadLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const handleLampirkan = (e) => {
    const file = e.target.files[0];

    clearErrors(name);

    if (file) {
      // validasi file size
      if (file.size > 50_000_000) {
        setError(
          name,
          {
            type: "manual",
            message: "File too big, max 50MB",
          },
          {
            shouldFocus: true,
          }
        );
      } else {
        setUploadLoading(true);
        disableNext(true);
        disableAddField(true);

        const fd = new FormData();

        fd.append("claimCargoId", id);
        fd.append("file", file);
        fd.append("type", "DOC_OTHER");
        fd.append("description", field.description);

        axiosPost({
          url: "/claim-cargo/upload",
          data: fd,
          callback: (res) => {
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            changeField(res.data, field.id);
          },
          errorCallback: (err) => {
            setUploadLoading(false);
            disableNext(false);
            disableAddField(false);
            console.log(err);
          },
        });
      }
    }
  };

  const handleDownload = (fileId, filename) => {
    setDownloadLoading(true);

    axiosGet({
      url: `/claim-cargo/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setDownloadLoading(false);
      },
      errorCallback: (res) => {
        setDownloadLoading(false);

        let file = new File([res], filename);
        saveAs(file);
      },
    });
  };

  const handleDelete = (id, fileId) => {
    // jika file sudah diupload, hapus dari db
    if (isExist) {
      setDeleteLoading(true);
      axiosDelete({
        url: `/claim-cargo/delete/file-id/${fileId}`,
        callback: () => {
          setDeleteLoading(false);
          // kemudian hapus field
          removeField(id);
        },
        errorCallback: (err) => {
          setDeleteLoading(false);
          console.log(err);
        },
      });
    } else {
      // jika belum, hanya hapus field
      removeField(id);
    }
  };

  const handleDescription = (e) => {
    clearErrors(desc);

    addDescription(e.target.value, field.id);
  };

  const handleError = () => {
    if (field.description) {
      clearErrors(desc);
    } else {
      setError(
        desc,
        {
          type: "manual",
          message: `${descLabel} required before upload`,
        },
        {
          shouldFocus: true,
        }
      );
    }
  };

  return (
    <>
      <label>Dokumen Lainnya</label>

      <MDBRow className="g-2">
        <MDBCol md="4">
          <label htmlFor={desc}>{descLabel}</label>

          {isExist ? (
            <StyledInput>{field.description}</StyledInput>
          ) : (
            <>
              <input
                type="text"
                className="form-control form-control-lg"
                id={desc}
                name={desc}
                {...register(desc, {
                  required: `${descLabel} required before upload`,
                  onChange: handleDescription,
                })}
                placeholder={descLabel}
                defaultValue=""
              />

              {errors[desc] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[desc].message}
                </p>
              )}
            </>
          )}
        </MDBCol>

        <MDBCol md="8">
          <label>File Name</label>

          {isExist ? (
            <StyledInput>
              <span>{field.filename}</span>

              <div>
                <StyledButton
                  type="button"
                  disabled={downloadLoading}
                  onClick={() => handleDownload(field.fileId, field.filename)}
                  isDownload
                >
                  {downloadLoading ? (
                    <MDBSpinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    "Download"
                  )}
                </StyledButton>

                <StyledButton
                  type="button"
                  onClick={() => handleDelete(field.id, field.fileId)}
                  disabled={deleteLoading || uploadLoading}
                  isDelete
                >
                  {deleteLoading ? (
                    <MDBSpinner role="status" size="sm">
                      <span className="visually-hidden">Loading...</span>
                    </MDBSpinner>
                  ) : (
                    <MDBIcon fas icon="trash" />
                  )}
                </StyledButton>
              </div>
            </StyledInput>
          ) : (
            <>
              <StyledWrapper>
                <input
                  type="file"
                  className="form-control form-control-lg"
                  id={name}
                  name={name}
                  {...register(name, {
                    required: field.description && "Please attach a file",
                    onChange: handleLampirkan,
                  })}
                  accept=".jpg, .jpeg, .png, .pdf, .mp4, .3gp, .webm, .mkv, .avi, .mpeg, .m4v"
                  disabled={!field.description}
                />

                <StyledAbsoluteInput>
                  <span></span>

                  <div>
                    <label htmlFor={name}>
                      <div style={{ cursor: "pointer" }} onClick={handleError}>
                        <StyledButton
                          type="button"
                          disabled={uploadLoading}
                          isUpload
                        >
                          {uploadLoading ? (
                            <MDBSpinner role="status" size="sm">
                              <span className="visually-hidden">
                                Loading...
                              </span>
                            </MDBSpinner>
                          ) : (
                            "Lampirkan"
                          )}
                        </StyledButton>
                      </div>
                    </label>

                    <StyledButton
                      type="button"
                      onClick={() => handleDelete(field.id, field.fileId)}
                      disabled={deleteLoading || uploadLoading}
                      isDelete
                    >
                      {deleteLoading ? (
                        <MDBSpinner role="status" size="sm">
                          <span className="visually-hidden">Loading...</span>
                        </MDBSpinner>
                      ) : (
                        <MDBIcon fas icon="trash" />
                      )}
                    </StyledButton>
                  </div>
                </StyledAbsoluteInput>
              </StyledWrapper>

              {errors[name] && (
                <p className="mb-0 mt-1 text-danger small">
                  *{errors[name].message}
                </p>
              )}
            </>
          )}
        </MDBCol>
      </MDBRow>
    </>
  );
};

export default FileForm;
