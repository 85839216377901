import {
  MDBAccordion,
  MDBAccordionItem,
  MDBCol,
  MDBRow,
} from "mdb-react-ui-kit"
import { useEffect } from "react"
import { useForm, Controller } from "react-hook-form"
import NumberFormat from "react-number-format"
import Button from "../Button/Button"

const SearchForm = ({ inputs, handleSearch, handleReset, defVals }) => {
  const { handleSubmit, register, reset, control } = useForm({
    defaultValues: defVals,
  })

  const onSubmit = (data) => {
    handleSearch(data)
  }

  const onReset = () => {
    reset()
    handleReset()
  }

  useEffect(() => {
    reset(defVals)
  }, [defVals, reset])

  return (
    <MDBAccordion>
      <MDBAccordionItem collapseId="search-form" headerTitle="Search">
        <form onSubmit={handleSubmit(onSubmit)}>
          <MDBRow className="g-2 g-md-4">
            {inputs.map((input) => (
              <MDBCol md="6" lg="3" key={input.name}>
                <label htmlFor={input.name}>{input.label}</label>

                <Input
                  input={input}
                  rhf={{
                    register: register,
                    control: control,
                  }}
                />
              </MDBCol>
            ))}
          </MDBRow>

          <div className="d-flex justify-content-end mt-4">
            <div className="me-2">
              <Button small secondary onClick={onReset}>
                RESET
              </Button>
            </div>

            <Button small endIcon="search" type="submit">
              SEARCH
            </Button>
          </div>
        </form>
      </MDBAccordionItem>
    </MDBAccordion>
  )
}

const Input = ({ input, rhf }) => {
  const { name, label, type, options } = input
  const { register, control } = rhf

  switch (type) {
    case "dropdown":
      return (
        <div className="select-wrapper">
          <select
            className="form-control form-control-lg"
            {...register(name)}
            id={name}
          >
            {options.map((option) => (
              <option key={option.name} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
        </div>
      )

    case "date":
      return (
        <input
          type="date"
          className="form-control form-control-lg"
          id={name}
          {...register(name)}
          placeholder={label}
        />
      )

    case "currency":
      return (
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, onBlur, value, name, ref } }) => (
            <NumberFormat
              name={name}
              id={name}
              thousandSeparator="."
              decimalSeparator=","
              value={value}
              onValueChange={(value) => onChange(value.floatValue)}
              onBlur={onBlur}
              className="form-control form-control-lg"
              getInputRef={ref}
              placeholder={label}
            />
          )}
        />
      )

    default:
      return (
        <input
          type="text"
          className="form-control form-control-lg"
          id={name}
          {...register(name)}
          placeholder={label}
        />
      )
  }
}

export default SearchForm
