import { MDBCol, MDBRow } from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";
import { encode as base64_encode } from "base-64";
import Button from "../../components/Button/Button";
// import Subheading from "../../components/Subheading/Subheading";
import { useEffect, useState } from "react";
import Spinner from "../../components/Spinner/Spinner";
import CardWrapper from "../../components/Card/CardWrapper";
import { useForm } from "react-hook-form";
import axiosPost from "../../helpers/axiosPost";

export default function CheckClaim() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [err, setErr] = useState(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setLoading(false);
  }, []);

  function handleClaim(item) {
    const body = base64_encode(
      JSON.stringify({
        ...item,
        certificateOwner: item.insured,
      })
    );
    const url = `/?token=${sessionStorage.getItem(
      "token"
    )}&user=${sessionStorage.getItem("userCode")}&data=${body}`;
    return navigate(url);
  }

  const onSubmit = (data) => {
    setLoading(true);
    axiosPost({
      url: `/claim-cargo/check`,
      data: data,
      callback: (res) => {
        setLoading(false);
        handleClaim(res.data);
      },
      errorCallback: (err) => {
        setLoading(false);
        setErr(err);
      },
    });
  };

  const inputs = [
    {
      name: "certificateNo",
      label: "Certificate No",
      required: true,
    },
    {
      name: "policyNumber",
      label: "Policy Number",
      required: true,
    },
  ];

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <CardWrapper>
              <MDBRow className="g-4 py-3">
                {inputs.map((item, i) => (
                  <div key={i}>
                    <label htmlFor={item.name} key={i}>
                      {item.label}
                      {item.required && <span className="text-danger">*</span>}
                    </label>
                    <MDBCol size="12" key={i + 1}>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        id={item.name}
                        {...register(item.name, {
                          required:
                            item.required && `${item.label} is required`,
                        })}
                        placeholder={item.label}
                      />

                      {errors[item.name] && (
                        <p className="mb-0 mt-1 text-danger small">
                          *{errors[item.name].message}
                        </p>
                      )}
                    </MDBCol>
                  </div>
                ))}
                <MDBCol size="12">
                  {err && (
                    <p className="mb-0 mt-1 text-danger small">
                      *{err.message}
                    </p>
                  )}
                </MDBCol>
              </MDBRow>
            </CardWrapper>
            <div className="d-flex justify-content-end mt-5">
              <Button type="submit">Submit</Button>
            </div>
          </form>
        </>
      )}
    </section>
  );
}
