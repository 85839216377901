export const setDetailFromCargo = (payload) => {
  return {
    type: "SET_DETAIL_FROM_CARGO",
    payload,
  }
}

export const setDetailFromApi = (payload) => {
  return {
    type: "SET_DETAIL_FROM_API",
    payload,
  }
}

export const resetDetail = (payload) => {
  return {
    type: "RESET_DETAIL",
    payload,
  }
}
