import { MDBCard, MDBIcon } from "mdb-react-ui-kit"
import styled from "styled-components"
import Button from "../Button/Button"

const StyledDownloadCard = styled.div`
  font-family: "Avenir", sans-serif;
`

function DownloadCard({
  title,
  handleClick,
  loading,
  disabled,
  href,
  download,
}) {
  return (
    <StyledDownloadCard>
      <MDBCard>
        <div className="px-4 py-2 d-flex align-items-center justify-content-between">
          <p className="mb-0 fw-bold">{title}</p>

          <Button
            onClick={handleClick}
            iconOnly
            loading={loading}
            disabled={disabled}
            href={href}
            download={download}
          >
            <MDBIcon fas icon="download" />
          </Button>
        </div>
      </MDBCard>
    </StyledDownloadCard>
  )
}

export default DownloadCard
