import { MDBCol, MDBRow } from "mdb-react-ui-kit"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import CertificateDetail from "../../components/Detail/CertificateDetail"
import ClaimDetail from "../../components/Detail/ClaimDetail"
import { resetKlaim } from "../../store/klaim/actions"
import Button from "../../components/Button/Button"
import Subheading from "../../components/Subheading/Subheading"
import { useEffect, useState } from "react"
import axiosGet from "../../helpers/axiosGet"
import Spinner from "../../components/Spinner/Spinner"

export default function Summary() {
  const { name, id } = useParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [detail, setDetail] = useState({})

  const decode = window.atob(id)

  useEffect(() => {
    axiosGet({
      url: `/claim-cargo/id/${decode}`,
      callback: (res) => {
        setLoading(false)
        setDetail(res.data)
      },
      errorCallback: (err) => {
        setLoading(false)
        console.log(err)
      },
    })
  }, [decode])

  const handleSubmit = () => {
    dispatch(resetKlaim(true))
    navigate(`/riwayat/${id}`)
  }

  return (
    <section className="overflow-hidden">
      {loading ? (
        <Spinner height={70} />
      ) : (
        <>
          <MDBRow className="g-4">
            <MDBCol md="8">
              <ClaimDetail detail={detail} />
            </MDBCol>

            <MDBCol md="4">
              <Subheading>Detail Certificate</Subheading>

              <CertificateDetail detail={detail} />
            </MDBCol>
          </MDBRow>

          <div className="d-flex justify-content-between mt-5">
            <Button
              secondary
              startIcon="arrow-left"
              onClick={() => navigate(`/klaim/${name}/${id}/next`)}
            >
              Kembali
            </Button>

            <Button endIcon="arrow-right" onClick={handleSubmit}>
              Selanjutnya
            </Button>
          </div>
        </>
      )}
    </section>
  )
}
