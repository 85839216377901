import styled from "styled-components"
import { MDBIcon, MDBSpinner } from "mdb-react-ui-kit"

const StyledButton = styled.button`
  background: ${(props) =>
    props.transparent
      ? "transparent"
      : props.secondary
      ? "var(--secondary)"
      : "var(--primary)"};
  color: white;
  border-radius: 30px;
  font-weight: 700;
  font-size: 1rem;
  font-family: Helvetica, sans-serif;
  padding: ${(props) =>
    props.iconOnly ? "0.75rem" : props.small ? "0 1rem" : "0 1.25rem"};
  border: ${(props) => (props.transparent ? "white 2px solid" : "none")};
  min-width: ${(props) =>
    props.iconOnly ? 0 : props.small ? "120px" : "160px"};
  min-height: ${(props) =>
    props.iconOnly
      ? 0
      : props.small
      ? "34px"
      : props.transparent
      ? "40px"
      : "48px"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.fullWidth && "100%"};

  :disabled {
    background: #757575;
  }
`

const StyledLink = styled.a`
  background: var(--primary);
  color: white;
  padding: 0.75rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  :hover {
    color: white;
  }
`

const Button = ({
  children,
  href,
  loading,
  startIcon,
  endIcon,
  small,
  secondary,
  onClick,
  disabled,
  type,
  transparent,
  form,
  iconOnly,
  download,
  fullWidth,
}) => {
  return href ? (
    <StyledLink href={href} download={download}>
      {children}
    </StyledLink>
  ) : (
    <StyledButton
      small={small}
      secondary={secondary}
      onClick={onClick}
      disabled={disabled}
      type={type || "button"}
      transparent={transparent}
      form={form}
      iconOnly={iconOnly}
      fullWidth={fullWidth}
    >
      {loading ? (
        <MDBSpinner role="status" size="sm">
          <span className="visually-hidden">Loading...</span>
        </MDBSpinner>
      ) : (
        <>
          {startIcon && <MDBIcon fas icon={startIcon} className="me-2" />}

          {children}

          {endIcon && <MDBIcon fas icon={endIcon} className="ms-2" />}
        </>
      )}
    </StyledButton>
  )
}

export default Button
