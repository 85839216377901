import styled from "styled-components"

const StyledSubheading = styled.div`
  font-size: 1.25rem;
  font-family: "Avenir", sans-serif;
  font-weight: 600;
  margin-bottom: 1rem;
`

const Subheading = ({ children }) => {
  return <StyledSubheading>{children}</StyledSubheading>
}

export default Subheading
