const StatusList = [
  {
    code: "100",
    color: "#4E9F3D",
    text: "Selamat Klaim Anda Berhasil Terkirim Pada",
  },
  {
    code: "110",
    color: "#3A9288",
    text: "Mohon Lengkapi Dokumen Klaim Sebelum",
  },
  {
    code: "111",
    color: "#FFBB57",
    text: "Mohon Lengkapi Dokumen Klaim Sebelum",
  },
  {
    code: "120",
    color: "#8A2BE2",
    text: "Klaim Anda Sedang Dalam Pengecekan Pada",
  },
  {
    code: "130",
    color: "grey",
    text: "Submit LOD",
  },
  {
    code: "140",
    color: "#E983D8",
    text: "Mohon Lengkapi Dokumen LOD",
  },
  {
    code: "150",
    color: "#945389",
    text: "Dokumen LOD Sedang Dalam Pengecekan Pada",
  },
  {
    code: "200",
    color: "grey",
    text: "Submit Claim to Insurance",
  },
  {
    code: "201",
    color: "#FF7518",
    text: "Mohon Lengkapi Dokumen Klaim Sebelum",
  },
  { code: "210", color: "grey", text: "Submit Doc Claim to Insurance" },
  {
    code: "300",
    color: "#0E4DA4",
    text: "Klaim Anda Sudah Disetujui Pada",
  },
  {
    code: "310",
    color: "#00BCD4",
    text: "Selamat Klaim Berhasil Dibayar Pada",
  },
  { code: "400", color: "#FF1E56", text: "Maaf Klaim Belum Memenuhi Kriteria" },
]

export default StatusList
