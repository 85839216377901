import {
  MDBBtn,
  MDBModal,
  MDBModalBody,
  MDBModalContent,
  MDBModalDialog,
  MDBModalHeader,
  MDBModalTitle,
} from "mdb-react-ui-kit"

function Modal({ children, title, show, setShow, toggleModal }) {
  return (
    <MDBModal show={show} setShow={setShow} tabIndex="-1">
      <MDBModalDialog centered>
        <MDBModalContent>
          <MDBModalHeader>
            <MDBModalTitle className="fw-bold">{title}</MDBModalTitle>

            <MDBBtn
              className="btn-close"
              color="none"
              onClick={toggleModal}
            ></MDBBtn>
          </MDBModalHeader>

          <MDBModalBody>{children}</MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default Modal
