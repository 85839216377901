const initialState = {
  detailFromCargo: {},
  detailFromApi: {},
}

const detailReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DETAIL_FROM_CARGO":
      return {
        ...state,
        detailFromCargo: action.payload,
      }
    case "SET_DETAIL_FROM_API":
      return {
        ...state,
        detailFromApi: action.payload,
      }
    case "RESET_DETAIL":
      return (state = initialState)

    default:
      return state
  }
}

export default detailReducer
