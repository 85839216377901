import BannerImage from "../../assets/img/banner.svg"
import styled from "styled-components"

const StyledBanner = styled.div`
  padding-top: 63px;

  div {
    margin: 3rem 0;
    padding: 3rem;
    border-radius: 16px;
    color: white;
    background: linear-gradient(164.83deg, #43ba94 25.75%, #006881 93.9%);
    position: relative;
    text-align: center;

    img {
      display: none;
    }

    h3 {
      font-family: "Avenir", sans-serif;
      font-weight: 700;
    }

    p {
      font-family: "Poppins", sans-serif;
      font-size: 14px;
      margin-bottom: 0;
    }
  }

  @media (min-width: 992px) {
    div {
      margin: 6rem 0 4rem;
      padding: 4rem 3rem;
      text-align: left;
      z-index: 0;

      img {
        display: block;
        width: 100%;
        padding: 0 1rem;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }

  @media (min-width: 1200px) {
    div {
      margin: 8rem 0 6rem;
      padding: 6rem 4rem;

      img {
        padding: 0 3rem;
      }
    }
  }
`

const Banner = () => {
  return (
    <StyledBanner>
      <div>
        <img src={BannerImage} alt="polismall claim web" />

        <h3>Polismall Claim Portal</h3>
        <p>We provide Insurance to let you at ease.</p>
      </div>
    </StyledBanner>
  )
}

export default Banner
