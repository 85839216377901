export const setDataSearchRiwayat = (payload) => {
  return {
    type: "SET_DATA_SEARCH_RIWAYAT",
    payload,
  }
}

export const resetRiwayat = (payload) => {
  return {
    type: "RESET_RIWAYAT",
    payload,
  }
}
