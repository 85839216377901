import {
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBIcon,
  MDBCard,
  MDBCardBody,
} from "mdb-react-ui-kit"
import "./DataTable.css"
import { useNavigate } from "react-router-dom"
import Spinner from "../Spinner/Spinner"
import formatCurrency from "../../helpers/formatCurrency"
import moment from "moment"
import ReactPaginate from "react-paginate"
import styled from "styled-components"

const StyledDetail = styled.button`
  background: #006881;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`

export default function DataTable(props) {
  const { rows, headers, loading, error, page, totalPage, handlePage } = props

  const navigate = useNavigate()

  const handleDetail = (id) => {
    const encode = window.btoa(id)
    navigate(`/riwayat/${encode}`)
  }

  return (
    <MDBCard shadow="0">
      <MDBCardBody>
        {loading ? (
          <Spinner height={75} />
        ) : error ? (
          <div className="text-danger fw-bold text-center">{error.message}</div>
        ) : rows.length ? (
          <>
            <MDBTable hover responsive>
              <MDBTableHead>
                <tr>
                  {headers.map((header, index) => (
                    <th scope="col" key={index}>
                      {header.name}
                    </th>
                  ))}
                </tr>
              </MDBTableHead>
              <MDBTableBody>
                {rows.map((row) => (
                  <tr key={row.id}>
                    <td style={{ minWidth: 290 }}>
                      <div>{row.noClaim || "-"}</div>
                      <div>{row.certificateNo || "-"}</div>
                      <div>{row.claim.policyNumber || "-"}</div>
                    </td>
                    <td style={{ minWidth: 300 }}>
                      <div>{row.insured || "-"}</div>
                      <div>{row.containerNo || "-"}</div>
                      <div>
                        {row.accidentDate
                          ? moment(row.accidentDate).format("DD/MM/YYYY")
                          : "-"}
                      </div>
                    </td>
                    <td style={{ minWidth: 180 }}>
                      <div>
                        {row.claim.claimAmount
                          ? `IDR ${formatCurrency(row.claim.claimAmount)}`
                          : "-"}
                      </div>
                      <div>
                        {row.claim.createdDate
                          ? moment(row.claim.createdDate).format("DD/MM/YYYY")
                          : "-"}
                      </div>
                      <div>{row.accidentType || "-"}</div>
                    </td>
                    <td style={{ minWidth: 300 }}>
                      <div>
                        {row.claim.sumInsured
                          ? `IDR ${formatCurrency(row.claim.sumInsured)}`
                          : "-"}
                      </div>
                      <div>
                        {row.claim.netPremium
                          ? `IDR ${formatCurrency(row.claim.netPremium)}`
                          : "-"}
                      </div>
                      <div>{row.claim.statusName || "-"}</div>
                    </td>
                    <td>
                      <div className="d-flex justify-content-center">
                        <StyledDetail onClick={() => handleDetail(row.id)}>
                          <MDBIcon icon="angle-right" size="lg" color="light" />
                        </StyledDetail>
                      </div>
                    </td>
                  </tr>
                ))}
              </MDBTableBody>
            </MDBTable>

            {totalPage > 1 && (
              <div className="mt-3 d-flex justify-content-center justify-content-md-end">
                <ReactPaginate
                  previousLabel={<MDBIcon icon="chevron-left" />}
                  nextLabel={<MDBIcon icon="chevron-right" />}
                  breakLabel={"..."}
                  pageCount={totalPage}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                  forcePage={page - 1}
                  onPageChange={handlePage}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  containerClassName={"pagination"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  activeClassName={"active"}
                />
              </div>
            )}
          </>
        ) : (
          <div className="fw-bold text-center">NO DATA</div>
        )}
      </MDBCardBody>
    </MDBCard>
  )
}
