import { Fragment, useState } from "react"
import { MDBCol, MDBIcon, MDBRow } from "mdb-react-ui-kit"
import { saveAs } from "file-saver"
import moment from "moment"
import styled from "styled-components"
import ReactPaginate from "react-paginate"

import axiosGet from "../../helpers/axiosGet"

import Button from "../Button/Button"
import CardWrapper from "../Card/CardWrapper"
import FileType from "../FileType"

const StyledAvenir = styled.div`
  font-family: "Avenir", sans-serif;
`

const StyledInsured = styled.div`
  font-weight: 700;
  border-radius: 0.25rem;
  background: #f5f5f7;
  padding: 1rem;
`

const ClaimDetail = ({ detail, claim, docs }) => {
  const docx = docs.filter(
    (doc) =>
      doc.type !== "DOC_LOD" &&
      doc.type !== "DOC_LOD_SIGNED" &&
      doc.type !== "DOC_PAYMENT" &&
      doc.type !== null
  )

  const documents = docx
    .filter((doc) => doc.type !== "DOC_OTHER")
    .map((doc) => {
      let text = ""

      FileType.forEach((element) => {
        if (element.type === doc.type) {
          text = element.text
        }

        if (doc.type === "DOC_SPGR_SIGNED") {
          text = "Surat Perjanjian Ganti Rugi Yang Telah ditandatangani"
        }
      })

      return { ...doc, text: text }
    })

  const otherDocuments = docx.filter((doc) => doc.type === "DOC_OTHER")

  const noClaim = detail.noClaim || "-"
  const containerNo = detail.containerNo || "-"
  const insured = detail.insured || "-"
  const createdDate = claim.createdDate
    ? moment(claim.createdDate).format("DD MMMM YYYY")
    : "-"
  const picEmail = claim.picEmail || "-"
  const picName = claim.picName || "-"
  const picPhoneNumber = claim.picPhoneNumber || "-"
  const accidentDate = detail.accidentDate
    ? moment(detail.accidentDate).format("DD MMMM YYYY")
    : "-"
  const accidentLocation = detail.accidentLocation || "-"
  const accidentDetail = detail.accidentDetail || "-"
  const accidentType = detail.accidentType
    ? detail.accidentType === "DAMAGE"
      ? "Damage Cargo"
      : "Shortage Cargo"
    : "-"

  return (
    <MDBRow className="g-4">
      <MDBCol size="12">
        <StyledAvenir>
          <MDBRow className="g-2">
            <MDBCol md="6">
              <p className="mb-0 small">No Klaim</p>
              <p className="mb-0 fw-bold">{noClaim}</p>
            </MDBCol>

            <MDBCol md="6">
              <p className="mb-0 small">No Kontainer</p>
              <p className="mb-0 fw-bold">{containerNo}</p>
            </MDBCol>
          </MDBRow>
        </StyledAvenir>
      </MDBCol>

      <MDBCol size="12">
        <CardWrapper>
          <p className="fw-bolder">Detail Klaim</p>

          <MDBRow className="g-2">
            <MDBCol size="12">
              <p className="mb-0 small">Insured</p>

              <StyledInsured>{insured}</StyledInsured>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">Tanggal Pengajuan</p>
              <p className="mb-0 fw-bold">{createdDate}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">Email PIC</p>
              <p className="mb-0 fw-bold">{picEmail}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">Nama PIC</p>
              <p className="mb-0 fw-bold">{picName}</p>
            </MDBCol>
            <MDBCol md="6">
              <p className="mb-0 small">No Handpone PIC</p>
              <p className="mb-0 fw-bold">{picPhoneNumber}</p>
            </MDBCol>
          </MDBRow>
        </CardWrapper>
      </MDBCol>

      <MDBCol size="12">
        <CardWrapper>
          <p className="fw-bolder">Detail Kronologi</p>

          <MDBRow className="g-2">
            <MDBCol size="12">
              <p className="mb-0 small">Tanggal Kejadian</p>
              <p className="mb-0 fw-bold">{accidentDate}</p>
            </MDBCol>
            <MDBCol size="12">
              <p className="mb-0 small">Lokasi Kejadian</p>
              <p className="mb-0 fw-bold">{accidentLocation}</p>
            </MDBCol>
            <MDBCol size="12">
              <p className="mb-0 small">Detail Kronologi Kejadian</p>
              <p className="mb-0 fw-bold">{accidentDetail}</p>
            </MDBCol>
            <MDBCol size="12">
              <p className="mb-0 small">Jenis Kejadian</p>
              <p className="mb-0 fw-bold">{accidentType}</p>
            </MDBCol>
            <MDBCol size="12">
              <p className="mb-0 small">Jenis Kerugian</p>
              <p className="mb-0 fw-bold">{detail.typeOfLoss || '-'}</p>
            </MDBCol>
          </MDBRow>
        </CardWrapper>
      </MDBCol>

      {documents?.length > 0 && (
        <MDBCol size="12">
          <CardWrapper>
            <p className="fw-bolder">Daftar Lampiran</p>

            <MDBRow className="g-2">
              {documents.map((doc) => (
                <MDBCol size="12" key={doc.id}>
                  <Document doc={doc} />
                </MDBCol>
              ))}
            </MDBRow>
          </CardWrapper>
        </MDBCol>
      )}

      {otherDocuments?.length > 0 && (
        <MDBCol size="12">
          <Documents docs={otherDocuments} />
        </MDBCol>
      )}
    </MDBRow>
  )
}

const Documents = ({ docs }) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [postsPerPage] = useState(5)

  const indexOfLastPost = currentPage * postsPerPage
  const indexOfFirstPost = indexOfLastPost - postsPerPage
  const currentPosts = docs.slice(indexOfFirstPost, indexOfLastPost)
  const pageCount = Math.ceil(docs.length / postsPerPage)

  const handlePage = (page) => {
    setCurrentPage(page.selected + 1)
  }

  return (
    <CardWrapper>
      <p className="fw-bolder">Dokumen Lainnya</p>

      <MDBRow className="g-2">
        {currentPosts.map((x) => (
          <MDBCol size="12" key={x.id}>
            <Document doc={x} />
          </MDBCol>
        ))}
      </MDBRow>

      {pageCount > 1 && (
        <div className="mt-4 d-flex justify-content-center justify-content-md-end">
          <ReactPaginate
            previousLabel={<MDBIcon icon="chevron-left" />}
            nextLabel={<MDBIcon icon="chevron-right" />}
            breakLabel={"..."}
            pageCount={pageCount}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={handlePage}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      )}
    </CardWrapper>
  )
}

const Document = ({ doc }) => {
  const [loading, setLoading] = useState(false)

  const handleDownload = (fileId, filename) => {
    setLoading(true)

    axiosGet({
      url: `/claim-cargo/download/file-id/${fileId}`,
      responseType: "blob",
      callback: () => {
        setLoading(false)
      },
      errorCallback: (res) => {
        setLoading(false)

        // save file to computer
        saveAs(res, filename)
      },
    })
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="mb-0">{doc.text || doc.description}</p>
          {
            doc.revisionNotes && (
              <div
                style={{
                  fontSize: '12px',
                  marginTop: '10px',
                  fontWeight: 'bold',
                  color: 'red',
                  fontStyle: 'italic'
                }}
              >
                Notes: {doc.revisionNotes}
              </div>
            )
          }
        </div>

        <Button
          loading={loading}
          disabled={loading}
          small
          onClick={() => handleDownload(doc.fileId, doc.filename)}
        >
          Download
        </Button>
      </div>

      <hr />
    </Fragment>
  )
}

export default ClaimDetail
