export default function ScrollToHere() {
  let element = document.getElementById("scrollToHere")
  let headerOffset = 63
  let elementPosition = element.getBoundingClientRect().top
  let offsetPosition = elementPosition + window.pageYOffset - headerOffset

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  })
}
