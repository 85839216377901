import { Routes, Route, Outlet } from "react-router-dom";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import Heading from "../../components/Heading/Heading";
import Summary from "./Summary";
import CheckClaim from "./CheckClaim";

export default function Klaim() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<FormOne />} />
        <Route path=":name/:id/next" element={<FormTwo />} />
        <Route path=":name/:id/summary" element={<Summary />} />
        <Route path="/check" element={<CheckClaim />} />
      </Route>
    </Routes>
  );
}

function Layout() {
  const currentPath = window.location.pathname;
  return (
    <>
      <Heading>
        {currentPath.includes("check") ? "CHECK KLAIM" : "FORM KLAIM"}
      </Heading>

      <Outlet />
    </>
  );
}
