import styled from "styled-components"

const StyledHeading = styled.div`
  margin-top: ${(props) => props.status && "18rem"};
  margin-bottom: 3rem;
  font-family: Helvetica, sans-serif;
  font-weight: 700;
  font-size: 1.5rem;
  text-align: center;

  hr {
    max-width: 100px;
    margin: 0 auto;
    border: #006881 solid 1px;
  }

  @media (min-width: 768px) {
    margin-top: ${(props) => props.status && "14rem"};
  }
`

const Heading = ({ children, ...props }) => {
  return (
    <StyledHeading {...props} id="scrollToHere">
      {children}

      <hr />
    </StyledHeading>
  )
}

export default Heading
